import {useState} from 'react';
import type {CountryData, RegionName, CountryName} from 'country-region-data';
import {allCountries} from 'country-region-data';

import {useTranslations} from '@/components/shared/Page/I18N';

export default (defaultValue?: CountryName) => {
  const [country, setCountry] = useState<CountryName | undefined>(defaultValue);
  const [region, setRegion] = useState<RegionName>();
  const {t} = useTranslations();
  const emptyValueOption = {
    label: t('forms:lead.country.defaultCountryOption'),
    value: '',
  };

  const getFormattedOptions = (rawValues: CountryData[] = []) =>
    rawValues.map(([value]: CountryData) => ({label: value, value}));

  const regions: {[key: string]: CountryData[]} = allCountries.reduce(
    (acc, [value, , currentRegions]) => ({...acc, [value]: currentRegions}),
    {},
  );

  return {
    country,
    setCountry,
    region,
    setRegion,
    countryOptions: [emptyValueOption, ...getFormattedOptions(allCountries)],
    regionOptions: [
      emptyValueOption,
      ...getFormattedOptions(regions[country || '']),
    ],
  };
};
