import {Select as SelectLegacy} from '@/components/shared/validatedForm/legacy/Select';
import {Select} from '@/components/shared/validatedForm/Select';
import {type SelectProps} from '@/components/shared/Select/Select';

interface Props extends SelectProps {
  legacyField?: boolean;
}

export default function SelectWrapper({legacyField, ...restProps}: Props) {
  return legacyField ? (
    <SelectLegacy {...restProps} />
  ) : (
    <Select {...restProps}>
      {restProps.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
}
