import {z} from 'zod';
import {allCountries} from 'country-region-data';
import type {CountryData, CountryName} from 'country-region-data';
import type {ReactNode} from 'react';

import {type SelectProps} from '@/components/shared/Select/Select';
import {useTranslations} from '@/hooks/useTranslations';

import useCountryRegionSelect from '../hooks/useCountryRegionSelect';
import SelectWrapper from '../SelectWrapper';

type Props = {
  selectProps?: Omit<SelectProps, 'id' | 'name' | 'options'>;
  legacyField?: boolean;
  showRegion?: boolean;
  defaultCountry?: CountryName;
};

const countryValues = allCountries.map(
  ([value]: CountryData) => value as string,
);

export const countryRegionValidation = {
  country: z.string().refine(
    (value) => {
      return countryValues.includes(value);
    },
    {message: 'forms:lead.country.error'},
  ),
  state: z.string().min(1, {message: 'forms:lead.state.error'}),
};

export default function CountryRegionSelect({
  selectProps,
  legacyField,
  showRegion = false,
  defaultCountry,
}: Props) {
  const {t} = useTranslations();

  const {
    country,
    setCountry,
    region,
    setRegion,
    countryOptions,
    regionOptions,
  } = useCountryRegionSelect(defaultCountry);

  const ConditionalFlexWrapper = ({children}: {children: ReactNode}) => {
    return showRegion ? (
      <div className="flex flex-col md:flex-row gap-x-gutter">{children}</div>
    ) : (
      <>{children}</>
    );
  };

  return (
    <ConditionalFlexWrapper>
      <SelectWrapper
        {...selectProps}
        legacyField={legacyField}
        label={t(`forms:lead.country.label`)}
        id="country"
        name="country"
        value={country}
        options={countryOptions}
        divider
        onChange={(e) => {
          setCountry(e.target.value as CountryName);
        }}
      />
      {showRegion && (
        <SelectWrapper
          {...selectProps}
          legacyField={legacyField}
          label={t(`forms:lead.state.label`)}
          id="state"
          name="state"
          value={region}
          options={regionOptions}
          divider
          onChange={(e) => {
            setRegion(e.target.value as CountryName);
          }}
        />
      )}
    </ConditionalFlexWrapper>
  );
}
